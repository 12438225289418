.post-details-component {
  .picture-container {
    .picture {
      position: relative;
      width: 100%;
      height: 200px;
      margin: 0 auto;
      background-color: #f9f9f9;
      border: solid 1px #f1f1f1;
      border-radius: 3px;

      label {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(249, 249, 249, 1);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.25s ease;

        &.hide {
          opacity: 0;

          &:hover {
            opacity: 1;
          }
        }

        .mat-icon {
          font-size: 80px;
          width: auto;
          height: auto;
          color: #d8d8d8;
        }
      }

      .picture-preview {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .picture-help {
      display: block;
      text-align: center;
      margin-top: 10px;
      color: #777;
      font-size: 13px;
    }

    button {
      margin: 10px 0;
    }
  }
}
