.notification-details{

    .status{
        font-size: 14px;
        .status-label{
            font-weight: bold;
            margin-right: 5px;
            font-size: 12px;
        }
        .status-value{
            font-size: 10px;
            padding: 2px 6px;
            border-radius: 5px;
            background-color: var(--accent);
            border: 1px solid var(--gray-color-2);
            font-weight: 600;
            &-status_active{
                color: var(--green-color-2);
            }
            &-status_inactive{
                color: var(--red-color-2);
            }
            &-status_pending{
                color: var(--yellow-color-2);
            }
            &-status_accepted{
                color: var(--green-color-2);
            }
            &-status_rejected{
                color: var(--red-color-2);
            }
            &-status_deleted{
                color: var(--red-color-2);
            }
            &-status_processing{
                color: var(--yellow-color-2);
            }
            &-status_delivered{
                color: var(--green-color-2);
            }
            &-status_draft{
                color: var(--yellow-color-4);
            }
            &-status_scheduled{
                color: var(--blue-color-1);
            }
            &-status_sending{
                color: var(--blue-color-1);
            }
            &-status_sent{
                color: var(--green-color-2);
            }
            &-status_cancelled{
                color: var(--red-color-2);
            }
            &-status_new{
                color: var(--yellow-color-2);
            }
            &-status_completed{
                color: var(--green-color-2);
            }
            &-status_not-participate{
                color: var(--red-color-2);
            }
            &-status_deleting{
                color: var(--red-color-2);
            }
            &-status_pause{
                color: var(--red-color-2);
            }
            &-status_generating{
                color: var(--yellow-color-2);
            }
            &-status_generated{
                color: var(--green-color-2);
            }
            &-status_generate{
                color: var(--blue-color-1);
            }
        }
    }

    .for-generate-info{
        text-align: center;
        h2{
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        p{
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
}

.disabled-header-click{
    .mat-horizontal-stepper-header-container{
        pointer-events: none;
    }
}