.gir-catalog-component {
  .mat-card {
    transition: all 0.25s ease;
    display: block;
    width: 100%;
    margin: 20px 0;

    &.selected {
      background-color: $primary;
      color: #ffffff;
    }

    .image img {
      width: 310px;
      max-width: initial;
    }

    .info {
      padding: 10px;
    }
  }
}
