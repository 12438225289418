.sales-statistics-component {
    .total {
        margin: 30px 0 !important;
        font-size: 60px;
        text-align: center;
    }

    .chart {
        width: 100%;
        height: 400px;
    }

    .title {
        margin: 10px;
    }
}