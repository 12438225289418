.gifts-pins-component {
  .pins-files-container {
    padding: 20px;
    background-color: #ffffff;

    h2 {
        margin-bottom: 20px;
    }

    .table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;

      th {
        text-align: left;
        border-bottom: solid 2px #000;
      }

      td,
      th {
        padding: 10px 5px;
      }

      td {
          background-color: #f9f9f9;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;

      button,
      label {
        margin-left: 10px;
      }
    }

    .progress {
        width: 100%;
    }
  }
}
