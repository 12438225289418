/*Common styles*/
@import "scss/vars";
@import "scss/normalize";
@import "scss/theme";
@import "scss/partials/font-awesome.min";
@import "scss/partials/editor.pkgd.min";
@import "scss/partials/editor_style.min";
@import "scss/partials/instructions-editor";
@import "scss/base";

/*Components partials*/
@import "scss/partials/login.component";
@import "scss/partials/dashboard.component";
@import "scss/partials/dashboard.menu.component";
@import "scss/partials/active-phases.component";
@import "scss/partials/sales-statistics.component";
@import "scss/partials/questions-details.component";
@import "scss/partials/test-statistics.component";
@import "scss/partials/video-statistics.component";
@import "scss/partials/notification-segmentation.component";
@import "scss/partials/post-details.component";
@import "scss/partials/gifts-pins.component";
@import "scss/partials/elearning-content-details.component";
@import "scss/partials/home.component";
@import "scss/partials/bi.component";
@import "scss/partials/sale-details.component";
@import "scss/partials/catalog.gir-catalog.component";
@import "scss/partials/segmentation-users.component";
@import "scss/partials/notification-progress.component";
@import "scss/partials/notifications-details.component";

::ng-deep .mat-horizontal-stepper-header {
    pointer-events: none !important;
}
