.search-toolbar {
    display: block;
    padding: 16px 16px 0;
    background-color: #f5f5f5;
    text-align: right;

    .mat-form-field {
        margin-bottom: 0;
    }

    .search-button {
        vertical-align: top;
        margin: 10px 0 0 10px;
    }
}

.mat-mdc-chip-set {
    margin-top: 10px;
}