.test-statistics-component {
    height: 80vh;
  
    table {
      width: 100%;
    }
  
    tr.example-detail-row {
      height: 0;
    }
  
    tr.example-element-row:not(.example-expanded-row):hover {
      background: #777;
    }
  
    tr.example-element-row:not(.example-expanded-row):active {
      background: #efefef;
    }
  
    .example-element-row td {
      border-bottom-width: 0;
    }
  
    .example-element-detail {
      overflow: hidden;
      display: block;
    }

    .question {
      margin-top: 10px;
    }
  
    .correct {
        color: #4caf50;
    }

    .incorrect {
        color: #d9121c;
    }
  }