.dashboard-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .dashboard-toolbar {
        background-color: #ffffff;
        border-top: solid 3px $primary;
        z-index: 10;
    }

    .dashboard-is-mobile .dashboard-toolbar {
        position: fixed;
        z-index: 2;
    }

    h1.dashboard-app-name {
        margin-left: 8px;
    }

    .dashboard-sidenav-container {
        flex: 1;
    }

    .dashboard-sidenav {
        width: 250px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .dashboard-is-mobile .dashboard-sidenav-container {
        flex: 1 0 auto;
    }

    .dashboard-is-mobile .dashboard-sidenav {
        width: auto;
        max-width: 200px;
    }

    .dashboard-content {
        padding: 20px;
    }

    .toolbar-spacer {
        flex: 1 1 auto;
    }
}
