body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.25;
  font-size: 14px;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

img:not(.mat-card-image) {
  max-width: 100%;
}

.flex,
.flex-col,
.flex-row {
  display: flex;
}

.flex-row {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-spacer {
  flex: 1 1 auto;
}

.full-height-vh {
  height: 100vh;
}

.size8of8 {
  width: 100%;
}

.size7of8 {
  width: 87.5%;
}

.size6of8 {
  width: 75%;
}

.size5of8 {
  width: 62.5%;
}

.size4of8 {
  width: 50%;
}

.size3of8 {
  width: 37.5%;
}

.size2of8 {
  width: 25%;
}

.size1of8 {
  width: 12.5%;
}

.padding--lvl0 {
  padding: 0;
}

.padding--lvl1 {
  padding: 10px;
}

.padding--lvl2 {
  padding: 20px;
}

.padding--lvl3 {
  padding: 30px;
}

.padding--lvl4 {
  padding: 40px;
}

.padding-bottom--lvl0 {
  padding-bottom: 0;
}

.padding-bottom--lvl1 {
  padding-bottom: 10px;
}

.padding-bottom--lvl2 {
  padding-bottom: 20px;
}

.padding-bottom--lvl3 {
  padding-bottom: 30px;
}

.padding-bottom--lvl4 {
  padding-bottom: 40px;
}

.padding-left--lvl1 {
  padding-left: 10px;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.justify-center {
  justify-content: center;
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.ml0 {
  margin-left: 0;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.mr0 {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr150 {
  margin-right: 150px;
}

.hidden {
  display: none;
}

.no-results {
  padding: 20px 10px;
  text-align: center;
  color: #aaa;
}

.green {
  color: #4caf50;
}

.blue {
  color: #1976d2;
}

.status-1,
.status-4,
.status-14,
.status-10 {
  color: #4caf50;
}

.status-2,
.status-5 {
  color: #d9121c;
}

.status-3 {
  color: #4285f4;
}

.status-7,
.status-3 {
  color: #ffa500;
}

small {
  display: block;
}

.small-text,
small {
  font-size: 12px;
  color: #999999;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.bg-gray {
  background-color: #e3e3e3 !important;
}

.fixed-bottom button {
  height: 46px;
  border-radius: 0;
}

.bold {
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mat-icon {
  vertical-align: middle;
}

.mat-button .mat-icon {
  margin-right: 5px;
}

.mat-card.only-picture {
  padding-bottom: 0;
}

.mat-card.only-picture img {
  display: block;
  line-height: 1;
  border-radius: 3px;
}

/*Menu*/
.admin-menu .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0;
  background-color: #f5f5f5;
}

.admin-menu .mat-nav-list {
  padding-top: 0;
}

.admin-menu .mat-expansion-panel-header .mat-expansion-indicator {
  height: 22px;
}

.admin-menu .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.7);
}

/*Menu*/

.kars-users-info .mat-grid-tile .mat-figure {
  align-items: flex-start;
}

.kars-users-info {
  margin-top: -30px;
  margin-left: -20px;
  margin-right: -20px;
}

.kars-users-info .mat-card {
  border-left: 1px solid #f1f1f1;
  border-radius: 0;
}

/*New notification*/
.new-notification .mat-grid-tile .mat-figure {
  align-items: initial;
  justify-content: initial;
}

/*New notification*/

.submit-wrapper {
  background-color: #f1f1f1;
}

.mat-drawer-container.admin-page {
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.mat-expanded {
  border-bottom: solid 1px #f1f1f1;
}

.mat-expanded .mat-expansion-panel-header,
.mat-expanded .mat-expansion-panel-header.mat-expanded:hover {
  background: #f5f5f5;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  align-items: center;
}

.mat-drawer-container.admin-page .mat-drawer-content {
  padding: 20px;
  box-sizing: border-box;
}

.mat-drawer-container.admin-inner-page .filters-container,
.mat-drawer-container.admin-page .filters-container {
  width: 300px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-drawer-container.admin-inner-page .filters-container .form-container,
.mat-drawer-container.admin-page .filters-container .form-container {
  padding: 20px;
}

.mat-drawer-container.admin-page .filters-container .form-container .mat-form-field {
  width: 100%;
}

.mat-drawer-container.admin-inner-page .filters-container .actions,
.mat-drawer-container.admin-page .filters-container .actions {
  text-align: right;
}

.mat-drawer-container.admin-inner-page .filters-container .actions button,
.mat-drawer-container.admin-page .filters-container .actions button {
  margin-left: 10px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.top-actions {
  margin-bottom: 20px;
}

.mat-toolbar.list-toolbar {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-toolbar.list-toolbar .selected-msg {
  font-size: 14px;
  font-weight: 400;
}


.toolbar-spacer {
  flex: 1 1 auto;
}

.mat-row {
  cursor: pointer;
  transition: all 0.25s ease;
}

.mat-row:hover {
  background-color: #f5f5f5;
}

.mat-progress-bar.thin {
  height: 2px;
}

.list-filters-drawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.list-container {
  position: relative;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-shade.fullh {
  bottom: 0;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.msg-notice {
  color: #888;
}

.msg-notice .mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  color: $accent;
  margin-right: 5px;
}

.help-icon {
  color: #1976d2;
}

.form-content .fr-toolbar {
  border-color: #1976d2;
}

.pointer {
  cursor: pointer;
}

/* Forms */

.form-container {
  .mat-bottom-sheet-container .loading-shade {
    bottom: 0;
  }
}

.form-container {
  .mat-bottom-sheet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-height: 100vh;
    width: 100%;
    min-width: 0% !important;
    max-width: 100%;
    z-index: 0 !important;
    background-color: #fafafa;
  }
}

.form-container-fullscreen {
  .mat-bottom-sheet-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    max-height: 100vh;
    width: 100%;
    min-width: 0% !important;
    z-index: 0 !important;
    background-color: #fafafa;
  }
}

.filters-container {
  width: 30%;
  .mat-bottom-sheet-container {
    width: 30%;
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-toolbar,
  .mat-bottom-sheet-container .form-toolbar {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-toolbar .close,
  .mat-bottom-sheet-container .form-toolbar .close {
    transform: rotate(0deg);
    transition: all 0.25s ease;
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-toolbar .close:hover,
  .mat-bottom-sheet-container .form-toolbar .close:hover {
    transform: rotate(90deg);
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-toolbar .title,
  .mat-bottom-sheet-container .form-toolbar .title {
    margin-left: 10px;
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-content,
  .mat-bottom-sheet-container .form-content {
    max-width: 90%;
    margin: 76px auto 0;
  }
}

.form-container,
.form-container-fullscreen {

  .mat-bottom-sheet-container .form-content .mat-form-field,
  .mat-bottom-sheet-container .form-content .mat-form-field {
    margin-bottom: 20px;
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}

.mat-tab-label-content .mat-icon {
  font-size: 18px;
  line-height: 24px;
  margin-right: 5px;
}

.form-content .mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

.form-content .mat-tab-body-content {
  padding: 5px;
  overflow: hidden;
}

.form-content.import {
  max-width: 300px;
}

.form-content .upload-area {
  //width: 100%;
  height: 100px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: solid 1px #f1f1f1;
  border-radius: 3px;
  position: relative;
}

.form-content .import-data .upload-area {
  height: 150px;
}

.form-content .import-data .upload-area .mat-icon {
  font-size: 70px;
  height: 70px;
  width: 70px;
}

.form-content .upload-area label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(249, 249, 249, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease;
}

.form-content .upload-area label.hide {
  display: none;
}

.form-content .upload-area .picture-preview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-content .upload-area .picture-preview img {
  max-height: 100%;
  cursor: pointer;
}

tr.mat-footer-row {
  font-weight: 500;
  background-color: #f9f9f9;
}

.front-page {
  padding: 20px;
}

/* Forms */

.pb50 {
  padding-bottom: 50px;
}

.info-card .mat-card-header-text {
  margin: 0;
}


.filter-option>.mat-pseudo-checkbox {
  display: none !important;
}

.filter-sidenav .mat-list-item-content {
  padding: 0 10px !important;
  font-size: 0.875rem !important;
}

.filter-sidenav .mat-select-trigger {
  font-size: 1rem !important;
}

@media (min-width: 1025px) {
  .mat-dialog-container {
    min-width: 350px;
  }
}

@media (max-width: 1367px) {
  .mat-bottom-sheet-container.form-container {
    width: 100%;
  }
}

@media (max-width: 600px) {

  .login-card-container .mat-form-field-label,
  .login-card-container .mat-input-element {
    color: #ffffff;
  }

  .login-card-container .mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: #ffffff;
  }
}


/*draft*/
.status-11 {
  color: #f0bc00;
}

/*scheduled*/
.status-12 {
  color: #1a73e8;
}

/*sending*/
.status-13 {
  color: #007d89;
}

/*sent*/
.status-14 {
  color: #4caf50;
}

/*cancelled*/
.status-15 {
  color: #c20000;
}