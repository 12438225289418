.bi-section-title {
  padding: 0 15px 10px;
  font-weight: 500;
  font-size: 24px;
}

.bi-card {
  padding: 15px;
  background-color: #fff;
  border-radius: 2px;
  margin: 0 10px 10px 10px;

  .header {
    display: flex;
    align-items: center;

    .title {
      flex: 1 1 auto;
      color: #9fa7af;
      font-size: 14px;

      .mat-icon {
        font-size: 14px;
        width: 14px;
        height: 16px;
        line-height: 1;
        cursor: help;
        vertical-align: middle;
        color: $blue;
      }
    }
  }

  .data {
    padding: 20px 0 10px;
    font-weight: 600;
    font-size: 32px;
  }

  .variation {
    font-size: 14px;
    display: inline;
    color: $green;
    padding-left: 3px;
    font-weight: 400;

    &.negative {
      color: $red;
    }
  }
}

.h-har-container {
  margin: 10px 0;

  .bar {
    padding-top: 7px;

    &.status4 {
      .mat-progress-bar-fill::after {
        background-color: $green;
      }
    }

    &.status3 {
      .mat-progress-bar-fill::after {
        background-color: $blue;
      }
    }

    &.status5 {
      .mat-progress-bar-fill::after {
        background-color: $red;
      }
    }

    .text {
      padding: 5px 0;
      font-size: 13px;
    }
  }
}

.resume-box {
  display: block;
  margin-top: 20px;
  border: solid 1px #f8f8f8;

  &:not(.auto-height) {
    min-height: 180px;
  }

  &.lines-bottom {
    .line {
      border-bottom: solid 1px #f8f8f8;
      font-size: 20px;
    }
  }

  .line {
    padding: 10px;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;

    &.header {
      font-size: 20px;
      color: #444;
      background-color: #f8f8f8;
    }

    &.blur {
      filter: blur(4px);
      background-color: #f8f8f8;
    }

    span {
      padding: 0 3px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.accent {
        color: $accent;
      }

      &.primary {
        color: $primary;
      }
    }

    .greater {
      font-size: 24px;
    }
  }
}

.bi-filters-component {
  padding-top: 74px;
  .mat-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

  }
}


.mat-icon.tooltip {
  cursor: help;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}