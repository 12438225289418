.dashboard-menu {
  .account-info {
    background-color: #f1f1f1;
    border-width: 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08);

    .account-icon {
      vertical-align: middle;
      line-height: 60px;
      font-size: 60px;
      width: 60px;
      height: 60px;
      margin-bottom: 5px;
    }

    .edit-profile {
      color: $primary;
      cursor: pointer;
      margin-top: 10px;
    }
  }

  .mat-nav-list {
    padding-top: 0;

    .mat-list-item {
      font-size: 14px;
      transition: all 0.25s ease;

      &.open {
        background-color: #eee;
      }

      &.active:not(.init) {
        background-color: $primary;
        color: #ffffff;
      }

      &.last {
        border-width: 1px 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.08);
      }

      .material-icons {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-right: 5px;
      }
    }
  }
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      font-size: 14px;
      padding: 0 16px;
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.08);

      .material-icons {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-right: 5px;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
        background-color: #f5f5f5;
      }
    }
  }
}
