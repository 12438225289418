.home-component {
    margin: -20px;
}

.charts-wrapper {
    display: block;

    button {
        .mat-icon {
            font-size: 20px;
        }
    }

    .row {
        display: flex;
        width: 100%;
    }

    .chart-container {
        margin: 5px;
        width: 100%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .chart {
            height: 100%;

            .mat-card-title {
                font-size: 16px;
            }

            .mat-card-header-text {
                margin: 0;

                &,
                .mat-card-title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
