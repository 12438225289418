.notification-segmentation-component,
.announcements-users-component {
  .counters {
    display: flex;

    .counter {
      min-height: 100px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin: 5px;

      h2 {
        text-align: center;
      }

      .total {
        font-size: 40px;
        line-height: 1.2;
      }
    }
  }

  th.mat-header-cell {
    background-color: #f5f5f5;
  }
}