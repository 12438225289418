.login-component  {
    .creativity-area {
        background-image: url('/assets/img/bgs/login.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left center;
    }
    
    .creativity-area>div {
        display: flex;
        align-items: flex-end;
    }
    
    .login-area {
        background-color: #f7f7f9;
        border-top: 3px solid $primary;
    }
    
    h1 {
        font-size: 54px;
        font-weight: 600;
        color: $primary;
    }
    
    h2 {
        font-size: 54px;
        color: #ffffff;
    }
    
    h3 {
        font-size: 36px;
        color: #242f3a;
    }
    
    .mat-flat-button {
        min-height: 37px;
    }
    
    .mat-flat-button .mat-spinner {
        margin: 0 auto
    }
    
    .mat-form-field {
        margin-bottom: 10px;
    }
    
    .logo {
        max-width: 250px;
    }
}