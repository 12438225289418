.active-phases-component {

  .new-label {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 35px solid $primary;

    span {
      color: #ffffff;
      font-size: 12px;
      position: absolute;
      left: 50%;
      top: -33px;
      transform: translateX(-50%);
      user-select: none;
    }
  }
}
